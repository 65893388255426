import numbro from "numbro";
import { DateTime } from "luxon";

export const truncateString = (value, length) => {

  if (value && value.length > 20) {
    const startStr = value.substring(0, length);
    const endString = value.slice(-length);
    return startStr + ' ... ' + endString;
  }

  return value;
}

export const formatCurrency = (value, decimals, length) => {

  const formattedCurrency = numbro(value).formatCurrency({ thousandSeparated: true, mantissa: decimals, });
  if (formattedCurrency && formattedCurrency.length > 25) {
    const startStr = formattedCurrency.substring(0, length);
    const endString = formattedCurrency.slice(-length);

    return startStr + ' ... ' + endString;
  }
  return formattedCurrency;
}

export const formatNumber = (value, decimals, length) => {

  const formattedCurrency = numbro(value).format({ thousandSeparated: true, mantissa: decimals, });
  if (formattedCurrency && formattedCurrency.length > 25) {
    const startStr = formattedCurrency.substring(0, length);
    const endString = formattedCurrency.slice(-length);

    return startStr + ' ... ' + endString;
  }
  return formattedCurrency;
}

export const subStringGenerator = (value, startIndex, length) => {

  if (value && value.length >= length) {
    return value.substring(startIndex, length);
  }

  return '';
}

export const formatDate = (value, format) => {

  if (value && value.length >= length) {
    return DateTime.fromISO(value).toFormat(format);
  }

  return '-';
}